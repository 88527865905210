<template>
  <div>
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <h3 style="margin-top: 15px" class="custom-header main-color">
      {{ $t("View Bank receipt") }}
    </h3>
    <v-divider></v-divider>
    <v-card>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <label>{{ $t("Receipt Code") }}</label>
          <p>{{ data.id }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <label>{{ $t("Child Name") }}:</label>
          <p>{{ data.applicant_name }}</p>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <label>{{ $t("Child Code") }}:</label>
          <p>{{ data.applicant_code }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <label>{{ $t("Bank Receipts") }}:</label>
          <v-img :src="data.receipt" width="900" height="500"></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <label>{{ $t("Notes") }}:</label>
          <p>{{ data.notes }}</p>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      valid: false,
      data: {
        applicant_id: "",
        file: "",
        notes: "",
      },
      applicants_data: [],
      id: "",
    };
  },
  methods: {
    getData() {
      axios
        .get(this.getApiUrl + "/payments/bank_receipts/" + this.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.data = response.data.data;
        });
    },
  },
  mounted() {
    this.id = this.$router.currentRoute.params.id;
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
.space {
  margin: 10px;
  float: right;
}
</style>
